// JsFromRoutes CacheKey 6f3d79c2ea70653f1877fcd8226412a0
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  search: definePathHelper('post', '(/:admin)/datasheets/:datasheet_id/records/search'),
  insert: definePathHelper('post', '(/:admin)/datasheets/:datasheet_id/records/insert'),
  values: definePathHelper('post', '(/:admin)/datasheets/:datasheet_id/records/values'),
  list: definePathHelper('get', '(/:admin)/datasheets/:datasheet_id/records'),
  destroy: definePathHelper('delete', '(/:admin)/datasheets/:datasheet_id/records/:id'),
}
