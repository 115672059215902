// JsFromRoutes CacheKey e729721d41f9364b08d51096430694de
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  create: definePathHelper('post', '(/:admin)/datasheets/:datasheet_id/imports'),
  new: definePathHelper('get', '(/:admin)/datasheets/:datasheet_id/imports/new'),
  get: definePathHelper('get', '(/:admin)/datasheets/:datasheet_id/imports/:id'),
}
