// JsFromRoutes CacheKey 94b5fcebf00e9b939fdd4b0d5f054389
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/drive'),
  requestAccessToDocument: definePathHelper('post', '/drive/:id/request_access_to_document'),
  addCurrentUserAsParticipant: definePathHelper('post', '/drive/:id/add_current_user_as_participant'),
  addCurrentUserAsParticipantToClip: definePathHelper('post', '/drive/:id/add_current_user_as_participant_to_clip'),
  templatePackDocuments: definePathHelper('get', '/drive/:id/template_pack_documents'),
  queryObjects: definePathHelper('post', '/drive/query_objects'),
}
