// JsFromRoutes CacheKey ffcd499121e9b0dc841af71881a2f8ef
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: definePathHelper('get', '/users/password/new'),
  edit: definePathHelper('get', '/users/password/edit'),
  update: definePathHelper('patch', '/users/password'),
  create: definePathHelper('post', '/users/password'),
  validatePassword: definePathHelper('post', '/users/passwords/validate_password'),
}
