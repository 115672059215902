// JsFromRoutes CacheKey e48656550df92fde94da1523d36d2f83
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  synchronize: definePathHelper('post', '(/:admin)/datasheets/:id/synchronize'),
  synchronizationReferences: definePathHelper('post', '(/:admin)/datasheets/synchronization_references'),
  synchronizationLocations: definePathHelper('post', '(/:admin)/datasheets/synchronization_locations'),
  list: definePathHelper('get', '(/:admin)/datasheets'),
  create: definePathHelper('post', '(/:admin)/datasheets'),
  new: definePathHelper('get', '(/:admin)/datasheets/new'),
  edit: definePathHelper('get', '(/:admin)/datasheets/:id/edit'),
  get: definePathHelper('get', '(/:admin)/datasheets/:id'),
  update: definePathHelper('patch', '(/:admin)/datasheets/:id'),
  destroy: definePathHelper('delete', '(/:admin)/datasheets/:id'),
}
