// JsFromRoutes CacheKey aa1965c0125565f20880b7d95fdfd548
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/admin/organisations/:organisation_id/assets'),
  create: definePathHelper('post', '/admin/organisations/:organisation_id/assets'),
  new: definePathHelper('get', '/admin/organisations/:organisation_id/assets/new'),
  destroy: definePathHelper('delete', '/admin/organisations/:organisation_id/assets/:id'),
}
