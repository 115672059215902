// JsFromRoutes CacheKey 5cb8360bbabbc20b62f63df8b9ce4851
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  adminEdit: definePathHelper('get', '/organisations/:id/admin_edit'),
  previewEmail: definePathHelper('get', '/organisations/:id/preview_email'),
  migrateEmails: definePathHelper('post', '/organisations/:id/migrate_emails'),
  splashscreen: definePathHelper('delete', '/organisations/:id/splashscreen'),
  emailHeaderBackground: definePathHelper('delete', '/organisations/:id/email_header_background'),
  ssoIdpMetadataXml: definePathHelper('delete', '/organisations/:id/sso_idp_metadata_xml'),
  fetchUsersAndGroups: definePathHelper('get', '/organisations/fetch_users_and_groups'),
  create: definePathHelper('post', '/organisations'),
  new: definePathHelper('get', '/organisations/new'),
  update: definePathHelper('patch', '/organisations/:id'),
  destroy: definePathHelper('delete', '/organisations/:id'),
}
